/****************************************
navigation
****************************************/
.navigation {

    &-wrapper {
        width: 100%;
        position: fixed;
        top: 3.6rem;
        left: 0;
        z-index: 500;

        @include drop-shadow($color: var(--gradient-blue-1-rgba-2));

        @include animateIt;
    }

    &-inner {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;    
        background-color: transparent;

        @include animateIt;
    }

    .social {
        display: none;
        align-items: center;

        position: absolute;
        bottom: 27px;
        z-index: 2;

        padding: 0 30px 0 40px;

        h6 {
            font-weight: 700;
            color: #fff;
        }

        ul {
            list-style: none;
            margin-left: 3rem;
            li {
                display: inline-block;


                &:not(:last-child) {
                    margin-right: 1rem;
                }

                a {
                    display: block;
                    width: 46px;
                    height: 46px;

                    border-radius: 50%;
                    background-color: #fff;

                    position: relative;

                    @include animateIt;

                    i {
                        font-size: 2rem;
                        color: var(--gradient-blue-1);

                        @include absoluteCenter;
                    }

                    &:hover {
                        background-color: var(--pink-1);
                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }

        @media (max-width: 350px) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            ul {
                margin: 2rem 0 0 0;
            }
        }
    }

    &-menu {
        .parent {
            margin: 0;
            > li {
                list-style: none;
                display: inline-block;
                position: relative;
                > a {
                    position: relative;
                    z-index: 50;
                    > span {
                        font-size: 1.6rem;
                        font-weight: 700;
                        color: $font-grey;
                    }
                }
                & + li {
                    margin-left: 4.5rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5rem;
                    z-index: 1;
                }

                .child {
                    position: absolute;
                    top: 8rem;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 25rem;
                    
                    background-color: #fff;
                    @include drop-shadow($color: var(--gradient-purple-1-rgba-1));
                    @include border-gradient(blue);
                    @include border-radius(2rem);
                    @include animateIt;
                    
                    padding: .3rem;
                    
                    ul {
                        background-color: #fff;
                        padding: 1rem 4rem 1rem 2rem;
                        
                        @include border-radius(1.7rem);
                    }
                    
                    li {
                        list-style: none;
                        
                        a {
                            display: block;
                            
                            
                            padding: 1.5rem 0 1.5rem 2rem;
                            @include animateIt;

                            span {
                                font-size: 1.5rem;
                                color: $font-grey;
                                @include animateIt;

                                &::before {
                                    background-color: $font-dark;
                                }
                            }

                            &:hover {
                                background-color: #fff;
                                span {
                                    color: $font-dark;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .child {
                        top: 4rem;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .mobile-header {
            display: none;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px 0 40px;
            margin: 21px 0 24px 0;
            ul {
                list-style: none;
                margin: 0;
                li {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 35px;
                    height: 35px;
                    background-color: #fff;
                    border-radius: 50%;
                    i {
                        font-size: 14px;
                        // color: $primary-color;
                    }
                    &:nth-child(n+2) {
                        margin-left: .5rem;
                    }
                }
            }
        }

        .search {
            display: none;
            position: relative;
            input {
                width: 100%;
                height: 5.5rem;
                // background-color: $primary-color-light-2;
                border: none;
                outline-color: transparent;
                
    
                font-size: 1.6rem;
                color: $font-grey;
    
                padding: 0 5rem 0 2.8rem;
    
            }
            button {
                background: transparent;
                border: none;
                position: absolute;
    
                top: 50%;
                right: 2.8rem;
                transform: translateY(-50%);
                
                i {
                    font-size: 1.6rem;
                    // color: $primary-color;
                }
            }
        }

        .tagline {
            width: 35%;
            font-size: 14px;
            font-weight: 500;
            line-height: 2rem;
            color: #fff;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2.3rem;
            display: none;
        }

        .background-pattern {
            display: none;
        }
        
        @media (max-width: 991px) {
            position: absolute;
            top: -3.6rem;
            left: -100%;
            width: 100%;
            height: 100vh;
            
            @include animateIt;

            .close-button  {
                display: none;
                position: relative;
                z-index: 2;
            }

            .mobile-header {
                display: flex;
                position: relative;
                z-index: 2;
            }

            .tagline {
                display: block;
            }

            .background-pattern {
                display: block;
                top: 0;
                left: 0;
                width: 100%;
            }

            .parent {
                li {
                    display: block;
                    &::after {
                        display: none;
                    }
                    > a {
                        display: block;
                        > span {
                            display: block;
                            color: #fff;
                            width: 100%;
                            padding: 15px 40px;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }

                    span {
                        &::before {
                            display: none;
                        }
                    }

                    &.main-search {
                        display: none;
                    }

                    &.has-child {
                        a {
                            position: relative;
                            &::after {
                                content: '\f107';
                                font-family: 'Font Awesome 5 Free';
        
                                font-size: 20px;
                                font-weight: 900;
                                color: #fff;
        
                                position: absolute;
                                top: 50%;
                                right: 40px;
                                transform: translateY(-50%);

                                @include animateIt;
                            }
                        }
                        .child {
                            box-shadow: none;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            border: none;
                            border-radius: 0;
                            z-index: 2;

                            ul {
                                border-radius: 0;
                            }

                            a {
                                &::after {
                                    content: '\f105';
                                }
                            }
                        }
                    }

                    .child {                        
                        position: relative;
                        top: 0 !important;
                        opacity: 1 !important;
                        visibility: visible;
                        display: none;
                        padding: 0;
                        background-color: #fff;
                        li {
                            a {
                                padding: 0;
                                span {
                                    display: inline-block;
                                    color: $font-grey;
                                    font-size: 20px;
                                    font-weight: 500;
                                    padding: 11.5px 40px;
                                }
                            }
                            &:first-child {
                                padding-top: 19.75px;
                            }
                            &:last-child {
                                padding-bottom: 19.75px;
                            }
                        }
                    }

                    & + li {
                        margin-left: 0;
                    }

                    &.has-child.dropped {
                        > a {
                            &::after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }
                        
                    }
                }
            }
        }

        @media (max-width: 350px) {
            .mobile-header {
                flex-direction: column;
                ul {
                    margin-top: 3rem;
                }
            }
        }

        @media (min-width: 992px) {
            .has-child {
                .child {
                    position: absolute !important;
                    max-height: initial !important;
                    -webkit-transition: all 0.25s ease !important;
                    -moz-transition: all 0.25s ease !important;
                    -o-transition: all 0.25s ease !important;
                    transition: all 0.25s ease !important;
                }
            }
        }

    }

    &.scrolled {
        .navigation-wrapper {
            top: 0;
            background-color: #fff;
        }

        .navigation-inner {
            padding: 3.1rem 0 3.1rem 0;
            @media (max-width: 991px) {
                padding: 3.1rem 2rem 3.1rem 2rem;
            }
        }
        .navigation-menu {
            @media (max-width: 991px) {
                top: 0;
            }
            .parent {
                li {
                    &:hover {
                        .child {
                            top: 5.7rem;
                        }
                    }
                }
            }
        }
    }

    &-bar {
        width: 4rem;
        height: 16px;
        display: none;
        flex-direction: column;
        
        justify-content: space-between;
        cursor: pointer;
        @include animateIt;
   
        
        div {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $font-dark;
        }
      

        @media (max-width: 991px) {
            display: flex;
        }
    }

    &-1:not(.scrolled) {
        .navigation-logo {
            position: relative;
            height: 3.3rem;

            a {
                position: absolute;
                @include animateIt;
            }

            .logo {
                opacity: 0;
                visibility: none;
            }
        }

        span::before {
            background-color: #fff;
        }

        .navigation-menu {
            .parent {
                > li {
                    > a {
                        > span {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .navigation-bar {
            div {
                background-color: #fff;
            }
        }
    }

    &-1.scrolled {
        .navigation-logo {

            .logo {
                opacity: 1;
                visibility: visible;
            }

            .logo-white {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &.shown {
        &:not(.scrolled) {
            .navigation-menu {
                top: -3.6rem;
                left: 0;
            }
        }

        &.scrolled {
            .navigation-menu {
                @media (max-width: 991px) {
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    @media (max-width: 991px) {
        &-inner {
            top: 8rem;
            align-items: center;
            padding: 0 2rem 0 2rem;
            .search {
                display: block;
            }
        }

        .social {
            display: flex;
        }
    }

    @media (max-width: 730px) {
        &-inner {
            top: 5rem;
        }
    }

}

/****************************************
top menu
****************************************/
.top-menu {
    &-wrapper {
        position: relative;
        
        left: 0;
        z-index: 500;

        width: 100%;
        padding: .8rem 5rem .8rem 5rem;

        background-color: #fff;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 991px) {
            padding: .8rem 2rem .8rem 2rem;
        }
    }

    .tagline {
        p {
            font-size: 1.3rem;
            font-weight: 500;
            color: $font-grey;
            margin: 0;
        }
    }

    .menu {
        ul {
            list-style: none;
            margin: 0;
            li {
                display: inline-block;

                & + li {
                    margin-left: 3.3rem;
                }

                &.phone {
                    font-size: 1.2rem;
                    font-weight: 500;
                    // color: $primary-color;
                    @include animateIt;
                    &:hover {
                        color: $font-grey;
                    }
                }
            }
        }
    }

    @media (max-width: 730px) {
        display: none;
    }
}

/****************************************
search
****************************************/
.main-search {
    cursor: pointer;
    &-lightbox {
        .gprev,
        .gnext {
            display: none;
        }
    }

    &-content {
        position: relative;
        input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
            padding-right: 6rem;
        }
        button {
            background: transparent;
            border: none;
            position: absolute;

            top: 50%;
            right: 2.8rem;
            transform: translateY(-50%);
            
            i {
                font-size: 1.6rem;
                // color: $primary-color;
            }
        }
    }
}